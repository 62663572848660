import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ProfilePic from "../assets/images/pic_crop.jpg"

const BlogIndex = ({ data, location }) => {
    return (
        <Layout location={location}>
            <SEO title="All posts" />
            <div className="w-full flex justify-center">
                <div className="">
                    <div className="flex justify-center mb-5">
                        <img
                            src={ProfilePic}
                            alt="Mihir Gupta"
                            style={{ maxWidth: "250px", width: "100%" }}
                        />
                    </div>
                    <div className="mx-auto lg:w-2/3 md:w-2/3 sm:w-5/6 w-full px-4 text-gray-800">
                        <p className="font-logoFont text-center my-5 text-4xl">
                            Hey! I'm Mihir Gupta
                        </p>
                        <p className="text-lg mx-2 text-gray-700 font-light font-roboto">
                            Welcome to my home in the cloud. <br />I make
                            websites for the web, write blogs and share what I
                            learn from books, people and experiences.
                            <br/><br/>
                            Currently I am documenting my journey as I learn different things in coding, life and other things. I call it <Link to='/voyage' className='text-blue-700 hover:text-blue-800 hover:underline' >The Voyage</Link>.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default BlogIndex
